import {BoxGeometry,
    CylinderGeometry,
    Color,
    BufferGeometry,
    BufferAttribute,
    ShaderMaterial,
    TextureLoader,
    AmbientLight,
    Points,
    PlaneBufferGeometry,
    MeshBasicMaterial,
    ImageUtils,
    DoubleSide,
    Mesh,
    WebGLRenderer,
    Scene,
    PerspectiveCamera,
    Vector3,
    DirectionalLight,
    RepeatWrapping,
    CubeCamera,
    LinearMipmapLinearFilter,
    Float32BufferAttribute,
    MeshStandardMaterial} from './three/three.module.js';

// import Stats from './jsm/libs/stats.module.js';

// import { GUI } from './jsm/libs/dat.gui.module.js';
//import { OrbitControls } from './jsm/controls/OrbitControls.js';
import { Water } from './jsm/objects/Water.js';
import { Sky } from './jsm/objects/Sky.js';

var container, stats;
var camera, scene, renderer, light;
var controls, water, sphere;

let height = typeof window !== `undefined` && (window.outerHeight);
height < 700 && (height = 700);

var particles;

var PARTICLE_SIZE = 18;

let lt = 0;
var Pgeometry;
let billmaterial;

function particlesAdd() {

    // var vertices = new BoxGeometry( 200, 200, 200, 16, 16, 16 ).vertices;
    var vertices = new CylinderGeometry(35, 35, 20, 32).vertices;

    var positions = new Float32Array(vertices.length * 3);
    var colors = new Float32Array(vertices.length * 3);
    var sizes = new Float32Array(vertices.length);

    var vertex;
    var color = new Color();

    for (var i = 0, l = vertices.length; i < l; i++) {

        vertex = vertices[i];
        vertex.toArray(positions, i * 3);

        color.setHSL(0.87 + 0.1 * (i / l), 1.0, 0.5);
        color.toArray(colors, i * 3);

        sizes[i] = PARTICLE_SIZE * 0.5;

    }

    Pgeometry = new BufferGeometry();
    Pgeometry.setAttribute('position', new BufferAttribute(positions, 3));
    Pgeometry.setAttribute('customColor', new BufferAttribute(colors, 3));
    Pgeometry.setAttribute('size', new BufferAttribute(sizes, 1));

    //

    var material = new ShaderMaterial({

        uniforms: {
            color: { value: new Color(0xffffff) },
            pointTexture: { value: new TextureLoader().load("/disc.png") }
        },
        // vertexShader: document.getElementById('vertexshader').textContent,
        // fragmentShader: document.getElementById('fragmentshader').textContent,

        alphaTest: 0.9

    });

    //
    var light = new AmbientLight(0x404040); // soft white light
    scene.add(light);
    Pgeometry.translate(0, 22, 0)
    Pgeometry.rotateX(0.1)
    particles = new Points(Pgeometry, material);
    // scene.add(particles);

    // transparent plane
    let bill = new PlaneBufferGeometry(115, 60, 1, 1);

    billmaterial = new MeshBasicMaterial({
        color: 0xFFFFFF,
        map: ImageUtils.loadTexture('/mount.png'),
        side: DoubleSide,
        transparent: true,
        opacity: 1, // red (can also use a CSS color string here)
        flatShading: true,
        depthWrite: false
    });

    let bm = new Mesh(bill, billmaterial);

    bm.position.set(-50, 20, -100);

    scene.add(bm);
}

function init() {
    
    if(typeof window !== `undefined`)
        window.addEventListener('resize', onWindowResize, false);
    if(typeof document !== `undefined`)
        document.addEventListener('gesturestart', function(e) {
            e.preventDefault();
        });

    container = document.getElementById('overwater');

    //

    renderer = new WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, height);
    container.appendChild(renderer.domElement);

    //

    scene = new Scene();

    //

    camera = new PerspectiveCamera(55, window.outerWidth / height, 1, 20000);
    camera.position.set(0, 7, 50);
    camera.lookAt(new Vector3(-40, 10, 0));
    //

    light = new DirectionalLight(0xffffff, 0.8);
    scene.add(light);

    particlesAdd();

    // Water

    var waterGeometry = new PlaneBufferGeometry(1000, 1000);

    water = new Water(
        waterGeometry, {
            textureWidth: 512,
            textureHeight: 512,
            waterNormals: new TextureLoader().load('/waternormals.png', function(texture) {

                texture.wrapS = texture.wrapT = RepeatWrapping;

            }),
            alpha: 1.0,
            sunDirection: light.position.clone().normalize(),
            sunColor: 0xff5555,
            waterColor: 0xf4c2c2,
            distortionScale: 7,
            fog: scene.fog !== undefined
        }
    );

    water.rotation.x = -Math.PI / 2;

    scene.add(water);

    // Skybox

    var sky = new Sky();

    var uniforms = sky.material.uniforms;

    uniforms['turbidity'].value = 10;
    uniforms['rayleigh'].value = 5;
    uniforms['luminance'].value = 1;
    uniforms['mieCoefficient'].value = 0.006;
    uniforms['mieDirectionalG'].value = 0.8;

    var parameters = {
        distance: 400,
        inclination: 0.30,
        azimuth: 0.205
    };

    var cubeCamera = new CubeCamera(0.1, 1, 512);
    cubeCamera.renderTarget.texture.generateMipmaps = true;
    cubeCamera.renderTarget.texture.minFilter = LinearMipmapLinearFilter;

    scene.background = cubeCamera.renderTarget;

    function updateSun() {

        var theta = Math.PI * (parameters.inclination - 0.5);
        var phi = 2 * Math.PI * (parameters.azimuth - 0.5);

        light.position.x = parameters.distance * Math.cos(phi);
        light.position.y = parameters.distance * Math.sin(phi) * Math.sin(theta);
        light.position.z = parameters.distance * Math.sin(phi) * Math.cos(theta);

        sky.material.uniforms['sunPosition'].value = light.position.copy(light.position);
        water.material.uniforms['sunDirection'].value.copy(light.position).normalize();

        cubeCamera.update(renderer, sky);

        billmaterial.color.r = 1 - (parameters.inclination - 0.3) * 3;
        billmaterial.color.g = 1 - (parameters.inclination - 0.3) * 4;
        billmaterial.color.b = 1 - (parameters.inclination - 0.3) * 5;

        //billmaterial.color = 0xFF0000


    }

    updateSun();

    //

    var geometry = new BoxGeometry(40, 20, 0.5);
    var material = new MeshBasicMaterial({ color: 0x000 });
    var cube = new Mesh(geometry, material);
    cube.rotateY(0.675);
    cube.position.set(-65, 15, 10);
    //scene.add( cube );

    // geometry.setAttribute( 'color', new Float32BufferAttribute( colors, 3 ) );

    // var material = new MeshStandardMaterial( {
    //   vertexColors: true,
    //   roughness: 0.0,
    //   flatShading: true,
    //   envMap: cubeCamera.renderTarget.texture,
    //   side: DoubleSide
    // } );

    // sphere = new Mesh( geometry, material );
    // scene.add( mesh );

    //
    /*
          controls = new OrbitControls( camera, renderer.domElement );
          controls.maxPolarAngle = Math.PI * 0.495;
          controls.target.set( 0, 10, 0 );
          controls.minDistance = 40.0;
          controls.maxDistance = 200.0;
          controls.update();
    */
    //

    // stats = new Stats();
    // container.appendChild( stats.dom );

    // GUI

    // var gui = new GUI();

    // var folder = gui.addFolder( 'Sky' );
    // folder.add( parameters, 'inclination', 0, 0.5, 0.0001 ).onChange( updateSun );
    // folder.add( parameters, 'azimuth', 0, 1, 0.0001 ).onChange( updateSun );
    // folder.open();

    // var uniforms = water.material.uniforms;

    // var folder = gui.addFolder( 'Water' );
    // folder.add( uniforms.distortionScale, 'value', 0, 8, 0.1 ).name( 'distortionScale' );
    // folder.add( uniforms.size, 'value', 0.1, 10, 0.1 ).name( 'size' );
    // folder.add( uniforms.alpha, 'value', 0.9, 1, .001 ).name( 'alpha' );
    // folder.open();

    //

    document.getElementById('scrollAnchor').addEventListener("scroll", (e) => {
        const p = document.getElementById('scrollAnchor2').getBoundingClientRect().top;
        let a = -p;
        a < 0 && (a = 0);
        a = a * 0.55 / (height / 2.5);
        a > 0.19 && (a = 0.19);

        parameters.inclination = 0.30 + a;

        updateSun();
    });
    
    animate();
}

function onWindowResize() {
    height = (window.outerHeight);
    height < 700 && (height = 700);
    camera.aspect = window.innerWidth / height;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, height);
}

function animate() {

    requestAnimationFrame(animate);
    render();
    // stats.update();

}

function render() {

    var time = performance.now() * 0.001;
    Pgeometry.rotateY((time - lt) / 3);

    lt = time;
    /*
          sphere.position.y = Math.sin( time ) * 20 + 5;
          sphere.rotation.x = time * 0.5;
          sphere.rotation.z = time * 0.51;*/

    water.material.uniforms['time'].value += 0.2 / 60.0;

    renderer.render(scene, camera);

}


export default init;